import React, { useState } from "react";
import { Link } from "react-router-dom";
import DashboardIcon from "../../svg/DashboardIcon";
import ManageStockIcon from "../../svg/ManageStockIcon";
import ReportsIcon from "../../svg/ReportsIcon";
import styles from "./Nav.module.scss";

export default function Nav(props) {
  const [activeNav, setActiveNav] = useState("dashboard");

  const setActiveNavHandler = (clickedNav) => {
    setActiveNav(clickedNav);
  };

  return (
    <nav>
      <ul className={styles.nav}>
        <li
          className={activeNav === "dashboard" ? styles.active : ""}
          onClick={() => {
            setActiveNavHandler("dashboard");
          }}
        >
          <Link
            to={`/inventory/dashboard`}
            className={[
              styles.link__item,
              props.isMenuCollapsed ? styles.collapsed : styles.expanded,
            ].join(" ")}
          >
            <DashboardIcon className={styles.link__icon} />
            {!props.isMenuCollapsed && "Dashboard"}
          </Link>
        </li>
        <li
          className={activeNav === "manage" ? styles.active : ""}
          onClick={() => {
            setActiveNavHandler("manage");
          }}
        >
          <Link
            to={`/inventory/manage-stock`}
            className={[
              styles.link__item,
              props.isMenuCollapsed ? styles.collapsed : styles.expanded,
            ].join(" ")}
          >
            <ManageStockIcon className={styles.link__icon} />
            {!props.isMenuCollapsed && "Manage Stock"}
          </Link>
        </li>
        <li
          className={activeNav === "reports" ? styles.active : ""}
          onClick={() => {
            setActiveNavHandler("reports");
          }}
        >
          <Link
            to={`/inventory/reports`}
            className={[
              styles.link__item,
              props.isMenuCollapsed ? styles.collapsed : styles.expanded,
            ].join(" ")}
          >
            {" "}
            <ReportsIcon className={styles.link__icon} />
            {!props.isMenuCollapsed && "Reports"}
          </Link>
        </li>
      </ul>
    </nav>
  );
}
