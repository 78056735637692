import React from "react";
import DashboardCard from "../components/DashboardCard/DashboardCard";
import ProductsGrid from "../components/ProductsGrid/ProductsGrid";
import styles from "./Dashboard.module.scss";

export default function Dashboard() {
  const menuBoxes = [
    {
      bgColour: "black",
      icon: "bell",
      title: "New Orders",
      count: 1,
      view: () => {},
    },
    {
      bgColour: "secondary",
      icon: "manage",
      title: "Low Stock",
      count: 0,
      view: () => {},
    },
    {
      bgColour: "primary",
      icon: "report",
      title: "Total products",
      count: 3,
      view: () => {},
    },
    {
      bgColour: "info",
      icon: "bell",
      title: "Todays Tasks",
      count: 4,
      view: () => {},
    },
  ];
  return (
    <div className={[styles.container, "u-mt-5"].join(" ")}>
      <div className={styles.cards}>
        {menuBoxes.map((box) => {
          return (
            <DashboardCard
              key={box.title}
              bgColour={box.bgColour}
              icon={box.icon}
              title={box.title}
              count={box.count}
              view={box.view}
            />
          );
        })}
      </div>
      <div className={styles.details}>
        <div className={styles.container}>
          <ProductsGrid />
        </div>
        <div className={styles.container}>
          <ProductsGrid />
        </div>
      </div>
    </div>
  );
}
