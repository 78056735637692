import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./LoginForm.module.scss";

export default function LoginForm() {
  const navigate = useNavigate();

  const loginHandler = (e) => {
    e.preventDefault();
    navigate("/inventory/dashboard");
  };

  return (
    <form
      action="#"
      className="contact-us__form form__form form"
      id="contactus_form"
    >
      <p className={styles.form__title}>Inventory Management</p>
      <div className={styles.form__group}>
        <input
          type="text"
          className="form__input"
          placeholder="Full name"
          id="name"
          required
          maxLength={40}
        />
        <label htmlFor="name" className="form__label">
          Full name
        </label>
        <div className="form__error">Full name is required</div>
      </div>
      <div className={styles.form__group}>
        <input
          type="email"
          className="form__input"
          placeholder="Email address"
          id="email"
          required
          maxLength={50}
        />
        <label htmlFor="email" className="form__label">
          Email address
        </label>
        <div className="form__error">Email address is required</div>
      </div>
      <div className="form__group center">
        <button
          id="contact_submit"
          className="btn btn--primary"
          onClick={loginHandler}
        >
          <img
            className="loader d-none"
            src="images/loader.svg"
            alt="preloader"
          />
          <span className="text">Login</span>
          <span className="arrow">&nbsp;&rarr;</span>
        </button>
      </div>

      <p className={styles.form__copyright}>
        © 2023 Key. All Rights Reserved | Design By Bhaargav Creations
      </p>
    </form>
  );
}
