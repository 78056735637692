import ReactDOM from "react-dom";

import ToastContainer from "./components/Toaster/ToastContainer";
// import TestComponent from "./components/TestComponent";

import "./App.scss";
import { Fragment, useContext } from "react";
import { ToastContext } from "./components/Store/Store";
import Login from "./pages/Login";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Error from "./pages/Error";
import Dashboard from "./pages/Dashboard";
import Reports from "./pages/Reports";
import ManageStocks from "./pages/ManageStocks";

const router = createBrowserRouter([
  {
    index: true,
    element: <Login />,
  },
  {
    path: "/inventory",
    element: <Home />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "manage-stock",
        element: <ManageStocks />,
      },
      {
        path: "reports",
        element: <Reports />,
      },
    ],
  },
  {
    path: "*",
    element: <Error />,
  },
]);

function App() {
  const toastCtx = useContext(ToastContext);

  return (
    <Fragment>
      {toastCtx.toastList?.length > 0 &&
        ReactDOM.createPortal(
          <ToastContainer />,
          document.getElementById("toast-root")
        )}
      <RouterProvider router={router} />
    </Fragment>
  );
}

export default App;
