import React from "react";
import LoginForm from "../components/LoginForm/LoginForm";
import styles from "./Login.module.scss";

export default function Login() {
  return (
    <div className={styles.loginPage}>
      <div className={styles.loginPage__formContainer}>
        <img
          className={styles.loginPage__logo}
          src="images/logo.svg"
          alt="logo"
        />
        <LoginForm />
      </div>
    </div>
  );
}
