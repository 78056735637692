import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import HorizontalRule from "../components/HorizontalRule/HorizontalRule";
import Nav from "../components/Nav/Nav";
import LeftDoubleArrowIcon from "../svg/LeftDoubleArrowIcon";
import LogoutIcon from "../svg/LogoutIcon";
import RightDoubleArrowIcon from "../svg/RightDoubleArrowIcon";
import styles from "./Home.module.scss";

export default function Home() {
  const navigate = useNavigate();
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);

  const logoutHandler = (e) => {
    e.preventDefault();
    navigate("/");
  };

  const leftArrowClickHandler = () => {
    setIsMenuCollapsed(true);
  };

  const rightArrowClickHandler = () => {
    setIsMenuCollapsed(false);
  };

  return (
    <div
      id="page-root"
      className={["page-root", isMenuCollapsed ? "collapsed" : "expanded"].join(
        " "
      )}
    >
      <div id={styles.sidebar}>
        {!isMenuCollapsed && (
          <LeftDoubleArrowIcon
            className={styles.sidebar__collapsearrow}
            leftArrowClickHandler={leftArrowClickHandler}
          />
        )}

        {isMenuCollapsed && (
          <RightDoubleArrowIcon
            className={styles.sidebar__collapsearrow}
            rightArrowClickHandler={rightArrowClickHandler}
          />
        )}

        <div
          className={[
            styles.sidebar__container,
            isMenuCollapsed ? styles.collapsed : styles.expanded,
          ].join(" ")}
        >
          <div
            className={[
              styles.logo__container,
              isMenuCollapsed ? styles.collapsed : styles.expanded,
            ].join(" ")}
          >
            <img src="../images/logo.svg" alt="logo" className={styles.logo} />
            {!isMenuCollapsed && <h1>Inentory App</h1>}
          </div>
          <HorizontalRule
            className={["horizontal", "light", "w-100"].join(" ")}
          />
          <Nav isMenuCollapsed={isMenuCollapsed} />
        </div>
        <button
          className={[
            styles.logout,
            "btn btn-primary bg-gradient-primary",
          ].join(" ")}
          onClick={logoutHandler}
        >
          <LogoutIcon
            className={[
              styles.logout__icon,
              isMenuCollapsed ? styles.collapsed : styles.expanded,
            ].join(" ")}
          />
          {!isMenuCollapsed && "Logout"}
        </button>
      </div>
      <div className={styles.pageContent}>
        <Header />
        <Outlet />
        <Footer />
      </div>
    </div>
  );
}
