import React from "react";
import styles from "./DashboardCard.module.scss";
import Icon from "../../svg/files/Icon";
import HorizontalRule from "../HorizontalRule/HorizontalRule";

const BgColour = (color) => {
  switch (color) {
    case "black":
      return styles.black;
    case "secondary":
      return styles.secondary;
    case "primary":
      return styles.primary;
    case "info":
      return styles.info;
    default:
      return "";
  }
};

export default function DashboardCard({ bgColour, icon, title, count, view }) {
  return (
    <div className={styles.card}>
      <div className={[styles.card__icon, BgColour(bgColour)].join(" ")}>
        <Icon iconname={icon} />
      </div>
      <div className={styles.card__count}>{count}</div>
      <div className={styles.card__title}>{title}</div>
      <HorizontalRule width="w-100" color="dark" />
      <div className={styles.card__btnContainer}>
        <a
          href="/home"
          className={[styles.card__button, "btn-view-more"].join(" ")}
        >
          Show
        </a>
      </div>
    </div>
  );
}
