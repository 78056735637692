import React, { useEffect, useState } from "react";

const ToastItem = (props) => {
  const { SETTINGS, RemoveToastItem, Item } = props;
  const TI_SETTINGS = SETTINGS.toast_item;

  const [progressWidth, setProgressWidth] = useState("100%");

  useEffect(() => {
    let timer;
    if (TI_SETTINGS.autoHide) {
      setProgressWidth("0%");
    }

    return () => {
      clearTimeout(timer);
    };
  }, [TI_SETTINGS.autoHide]);

  const progressDivStyle = {
    width: progressWidth,
    transition: `width ${
      TI_SETTINGS.autoHideInterval / 1000
    }s cubic-bezier(0, 0, 1, 1)`,
  };

  return (
    <div
      key={Item.id}
      className={`toast__item u-mt-1 ${Item.type ?? TI_SETTINGS.type} ${
        SETTINGS.container.position
      }`}
    >
      <p className="toast__item-text u-pl-2 u-pt-s-1 u-pb-s-1">
        {Item.text ?? TI_SETTINGS.text}
      </p>
      <div
        className="toast__item-close u-pl-1"
        onClick={() => {
          RemoveToastItem(Item.id);
        }}
      >
        &times;
      </div>
      <div className="toast__item-progress" style={progressDivStyle}></div>
    </div>
  );
};

// ToastItem.PropTypes = {
//   toastList: PropTypes.array.isRequired,
//   position: PropTypes.string
// }

export default React.memo(ToastItem);
