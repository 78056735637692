import ToastItem from "./ToastItem";
import React from "react";
import { useContext } from "react";
import { ToastContext } from "../Store/Store";

const ToastContainer = () => {
  const { toasterSettings, toastList, removeToastItem } =
    useContext(ToastContext);

  const TI_SETTINGS = toasterSettings.toast_item;

  return (
    <div
      className={`toast__container ${toasterSettings.container.position}`}
      style={{
        minWidth: `${toasterSettings.container.minWidth ?? "auto"}`,
      }}
    >
      <div className={`toast__items ${toasterSettings.container.position}`}>
        {toastList.map((item) => {
          if (TI_SETTINGS.autoHide) {
            setTimeout(() => {
              removeToastItem(item.id);
            }, TI_SETTINGS.autoHideInterval);
          }

          return (
            <ToastItem
              SETTINGS={toasterSettings}
              RemoveToastItem={removeToastItem}
              Item={item}
              key={item.id}
            />
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(ToastContainer);
