import React from "react";
import BellIcon from "../BellIcon";
import ManageStockIcon from "../ManageStockIcon";
import ReportsIcon from "../ReportsIcon";

export default function Icon({ iconname }) {
  switch (iconname) {
    case "bell":
      return <BellIcon height="2rem" width="2rem" />;
    case "manage":
      return <ManageStockIcon height="2rem" width="2rem" />;
    case "report":
      return <ReportsIcon height="2rem" width="2rem" />;
    default:
      return "";
  }
}
