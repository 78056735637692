import React from "react";
import styles from "./HorizontalRule.module.scss";

const Colour = (color) => {
  switch (color) {
    case "light":
      return styles.light;
    case "dark":
      return styles.dark;
    default:
      return styles.light;
  }
};

export default function HorizontalRule({ width, color }) {
  return (
    <hr className={[styles.horizontal, width, Colour(color)].join(" ")}></hr>
  );
}
