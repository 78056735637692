import { createContext, useReducer, useState } from "react";
import settings from "../../app-wide-settings.json";

export const ToastContext = createContext({
  toastList: [],
  addToastItem: () => {},
  removeToastItem: (id) => {},
});

const actionTypes = {
  add: "ADD_TOAST",
  remove: "REMOVE_TOAST",
};

const toastTopPosition = ["top-right", "top-center", "top-left"];

const reducerAction = (prevState, action) => {
  switch (action.type) {
    case actionTypes.add:
      if (toastTopPosition.includes(settings.toaster.container.position)) {
        return [action.payload, ...prevState];
      }
      return [...prevState, action.payload];
    case actionTypes.remove:
      return prevState.filter((item) => item.id !== action.payload);
    default:
      return prevState;
  }
};

export const ToastContextProvider = (props) => {
  const [toastList, toastListDispatch] = useReducer(reducerAction, []);
  const [toasterSettings, setToasterSettings] = useState(settings.toaster);

  const addToastItemHandler = (item) => {
    item.id = Math.random(); //TODO: Generate proper id

    toastListDispatch({
      type: actionTypes.add,
      payload: item,
    });
  };

  const removeToastItemHandler = (id) => {
    toastListDispatch({
      type: actionTypes.remove,
      payload: id,
    });
  };

  return (
    <ToastContext.Provider
      value={{
        toasterSettings: toasterSettings,
        setToasterSettings: setToasterSettings,
        toastList: toastList,
        addToastItem: addToastItemHandler,
        removeToastItem: removeToastItemHandler,
      }}
    >
      {props.children}
    </ToastContext.Provider>
  );
};
