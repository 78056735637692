import React from "react";

export default function ReportsIcon(props) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 28"
      width={props.width || "1.5rem"}
      height={props.height || "1.5rem"}
      fill={props.fill || "#ffffff"}
      className={props.className}
    >
      <path d="M4 24h12v-8h-12v8zM20 16h8v-12h-12v4h1.5c1.375 0 2.5 1.125 2.5 2.5v5.5zM32 2.5v15c0 1.375-1.125 2.5-2.5 2.5h-9.5v5.5c0 1.375-1.125 2.5-2.5 2.5h-15c-1.375 0-2.5-1.125-2.5-2.5v-15c0-1.375 1.125-2.5 2.5-2.5h9.5v-5.5c0-1.375 1.125-2.5 2.5-2.5h15c1.375 0 2.5 1.125 2.5 2.5z"></path>
    </svg>
  );
}
