import React from "react";
import BellIcon from "../../svg/BellIcon";
import UserIcon from "../../svg/UserIcon";
import styles from "./Header.module.scss";

export default function Header() {
  return (
    <div className={styles.headerContainer}>
      <div className="breadCrumb"> Home / Dashboard</div>
      <div className={styles.userContainer}>
        <div className={styles.userName}>
          <UserIcon fill="#777" width="1.2rem" height="1.2rem" />{" "}
          <span>Ritesh Fulari</span>
        </div>
        <div className={[styles.notifications, "pointer"].join(" ")}>
          <span className={styles.notifications__number}>10</span>
          <BellIcon fill="#777" width="1.2rem" height="1.2rem" />
        </div>
      </div>
    </div>
  );
}
