import React from "react";
import DataTable from "react-data-table-component";

// const ExpandedComponent = ({ data }) => (
//   <pre>{JSON.stringify(data, null, 2)}</pre>
// );

const columns = [
  {
    name: "Title",
    selector: (row) => row.title,
    // sortable: true,
  },
  {
    name: "Year",
    selector: (row) => row.year,
  },
];

const data = [
  {
    id: 1,
    title: "Beetlejuice",
    year: "1988",
  },
  {
    id: 2,
    title: "Ghostbusters",
    year: "1984",
  },
];

export default function ProductsGrid() {
  return (
    <DataTable
      columns={columns}
      data={data}
      // selectableRows
      // expandableRowsComponent={ExpandedComponent}
    />
  );
}
